import React, {useEffect, useState} from "react";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image"
import Link from "gatsby-link";
import {graphql} from "gatsby";
import {Button, Container, ImageList, ImageListItem, ImageListItemBar} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMedium} from '@fortawesome/free-brands-svg-icons'
import Layout from "../components/Layout/en";
import * as styles from './style.module.css';
import ToSimulatorButton from "../components/ToSimulatorButton";


const Index = ({data, location}) => {
	const [isWindow, setWindow] = useState(false);

	useEffect(() => {
		setWindow(typeof window !== `undefined`)
	}, [])

	const topics = data.allMarkdownRemark.edges;
	const frontendImageEdge = data.allFile.edges.filter((item) => item.node.relativePath === "front_end.jpg")[0]
	const backendImageEdge = data.allFile.edges.filter((item) => item.node.relativePath === "back_end.jpg")[0]

	return (
		<Layout
			title='PhilipNedelev.DEV'
			description='Website for beginner JavaScript developers'
			location={location}
		>
			<Container maxWidth="md">
				<h1>Site for beginner JS developers</h1>

				<div className={styles.promo}>
					<Link to={`/en/author`}>
						<StaticImage
							className={styles.avatar}
							src="../../static/img/avatar.png"
							placeholder="blurred"
							alt="my photo"
						/>
					</Link>
					<div className={styles.promoContent}>
						<p>
							Hi!<br/>
							Here you can find useful information that will help you in your studies and employment
						</p>
					</div>
				</div>

				<div className={styles.roadmaps}>
					<h2>
						JS Roadmaps {new Date().getFullYear()}
					</h2>
					<div className={styles.wrapper}>
						<Link to={`/en/front-end-roadmap`}>
							<div className={styles.item}
									 style={{backgroundImage: `url(${frontendImageEdge.node.childImageSharp.gatsbyImageData.images.fallback.src})`}}
							>
								<div className={styles.opacity}></div>
								<h3>Front-End Roadmap</h3>
							</div>
						</Link>
						<Link to={`/en/back-end-roadmap`}>
							<div className={styles.item}
									 style={{backgroundImage: `url(${backendImageEdge.node.childImageSharp.gatsbyImageData.images.fallback.src})`}}
							>
								<div className={styles.opacity}></div>
								<h3>Back-End Roadmap </h3>
							</div>
						</Link>
					</div>
				</div>



				{/*<div className={styles.simulator}>*/}
				{/*	<StaticImage*/}
				{/*		className={styles.image}*/}
				{/*		src="../../static/img/simulator.png"*/}
				{/*		placeholder="blurred"*/}
				{/*		alt="React logo"*/}
				{/*	/>*/}
				{/*	<div className='content'>*/}
				{/*		<h2>React Practicum</h2>*/}
				{/*		<span style={{lineHeight: "27px"}}>*/}
				{/*			Create a project in your portfolio by completing tasks from real projects and receiving feedback in code review*/}
				{/*		</span>*/}
				{/*		<div className='buttons' style={{marginTop: 15}}>*/}
				{/*			<Link to='/en/react-practicum'>*/}
				{/*				<Button style={{backgroundColor: "#ffe100", color: "#000"}} variant="contained">*/}
				{/*					Learn more*/}
				{/*				</Button>*/}
				{/*			</Link>*/}
				{/*			<ToSimulatorButton/>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}



				<div className={styles.answers}>
					<h2>
						Questions and Answers on Interviews
					</h2>
					<ImageList sx={{}}
										 cols={3}
										 // cols={isWindow && window.screen.width >= 480 ? 3 : 1}
					>
						{topics.map(({node: {frontmatter}}) => (
							<ImageListItem
								key={frontmatter.title}
								style={{height: isWindow && window.screen.width >= 350 ? "auto" : 120}}>
								<Link to={`/en/${frontmatter.questions_page}`}>
									<GatsbyImage
										image={frontmatter.image.childImageSharp.gatsbyImageData}
										alt={frontmatter.title}
										// style={{height: isWindow && window.screen.width >= 350 ? "auto" : 120}}
									/>
									<ImageListItemBar position="bottom" title={frontmatter.title}/>
								</Link>
							</ImageListItem>
						))}
					</ImageList>
				</div>
			</Container>
		</Layout>
	);
};

export const query = graphql`
    {
        allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "topics"}}}) {
            edges {
                node {
                    id
                    frontmatter {
                        title,
                        questions_page,
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: DOMINANT_COLOR
                                    formats: [AUTO, WEBP, AVIF]
                                    width: 350
                                    height: 350
                                )
                            }
                        }
                        slag
                    }
                }
            }
        }
        allFile(filter: {extension: {eq: "jpg"}}) {
            edges {
                node {
                    relativePath
                    childImageSharp {
                        gatsbyImageData(placeholder: DOMINANT_COLOR, formats: WEBP)
                    }
                }
            }
        }
    }
`

export default Index;
